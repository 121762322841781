<template>
  <div>
    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0" v-html="sidebarTitle"></h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <RequestDetails :reqItem="approveObj" />
        <b-row class="">
          <b-col cols="12">
            <b-form-group
              label="Date"
              invalid-feedback="Date is required"
              ref="date"
            >
              <flat-pickr
                :config="config"
                v-model="approveObj.leaving_date"
                class="form-control"
                placeholder="Select date"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Note"
              invalid-feedback="Note is required."
              ref="note"
            >
              <b-form-textarea
                placeholder="Enter note"
                rows="3"
                v-model.trim="approveObj.note"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <div class="d-flex justify-content-between">
              <label class="bv-no-focus-ring col-form-label pt-0"
                >Documents</label
              >
            </div>
            <div class="d-flex flex-wrap">
              <div
                class="border-dashed d-flex flex-column justify-content-center align-items-center cursor-pointer rounded mr-50 mb-1"
                style="width: 110px; height: 96px"
                @click="!docLoading && $refs.reqDocs.click()"
              >
                <b-spinner
                  v-if="docLoading"
                  small
                  label="Loading..."
                  variant="primary"
                />
                <feather-icon
                  v-else
                  icon="PlusIcon"
                  size="34"
                  class="text-primary"
                />
              </div>
              <div
                class="border-dashed d-flex flex-column justify-content-center align-items-center cursor-pointer rounded mr-50 mb-1"
                style="width: 110px; height: 96px; position: relative"
                v-for="(file, index) in docList"
                :key="index"
                @click="openFile(file)"
              >
                <b-avatar
                  size="20"
                  variant="primary"
                  style="position: absolute; top: -5px; right: -5px"
                  button
                  @click.stop="removeDoc(index)"
                >
                  <feather-icon icon="XIcon" />
                </b-avatar>
                <feather-icon
                  :icon="isImage(file) ? 'ImageIcon' : 'FileTextIcon'"
                  size="30"
                  class="text-primary"
                />
              </div>

              <input
                type="file"
                id="reqDocs"
                hidden
                ref="reqDocs"
                multiple
                @change="addDoc()"
                accept="image/*,.pdf"
              />
            </div>
          </b-col>

          <b-col class="mt-1" md="12">
            <b-button
              variant="success"
              :disabled="request"
              block
              @click="approveReq()"
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else>{{ sidebarButton }}</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility2"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0" v-html="sidebarTitle"></h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility2 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <RequestDetails :reqItem="rejectObj" />
        <b-row class="">
          <b-col cols="12">
            <b-form-group
              label="Note"
              invalid-feedback="Note is required."
              ref="rej_note"
            >
              <b-form-textarea
                placeholder="Enter note"
                rows="3"
                v-model.trim="rejectObj.note"
              />
            </b-form-group>
          </b-col>

          <b-col class="mt-1" md="12">
            <b-button
              variant="success"
              :disabled="request"
              block
              @click="rejectReq()"
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else> Reject </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility3"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0" v-html="sidebarTitle"></h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility3 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <RequestDetails :reqItem="detailObj" />
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility4"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Transfer Student</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility4 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="">
          <b-col md="12" class="mb-1">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="44"
                  :src="chObj.picture"
                  variant="light-primary"
                />
              </template>

              <b-link
                class="font-weight-bold d-block text-nowrap"
                style="margin-bottom: 4px"
              >
                <b-badge variant="light-primary">
                  {{ chObj.name }}
                </b-badge>
              </b-link>
              <small class="text-muted">
                <b-badge variant="light-primary" class="mr-50">
                  GR -
                  {{
                    reportDomain === "myskoolhpgs" ||
                    reportDomain === "myskoolprofectus"
                      ? chObj.grNoLocal
                      : chObj.grNo
                  }}
                </b-badge>
                <b-badge variant="light-primary" class="mr-50">
                  F# - {{ chObj.family_code }}
                </b-badge>
                <b-badge variant="light-primary">
                  {{
                    reportDomain === "myskoolhpgs" ||
                    reportDomain === "myskoolprofectus"
                      ? "CID: "
                      : "ID: "
                  }}

                  {{
                    reportDomain === "myskoolhpgs" ||
                    reportDomain === "myskoolprofectus"
                      ? chObj.grNo
                      : chObj.id
                  }}
                </b-badge>
              </small>
            </b-media>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Current Campus">
              <b-badge variant="primary">
                {{ getCampus(chObj.campusID) }}
              </b-badge>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Transfer to Campus"
              invalid-feedback="Campus is required."
              ref="ch_camp"
            >
              <v-select
                v-model="chObj.newCampus"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="campuses"
                :clearable="false"
                label="branch"
                :reduce="(val) => val.id"
                :disabled="clsLoading"
                placeholder="Select campus"
                @input="LoadClass()"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Class"
              invalid-feedback="class is required."
              ref="ch_cls"
            >
              <v-select
                v-model="chObj.clsID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="chOptions"
                :clearable="false"
                label="name"
                :reduce="(val) => val.id"
                :disabled="clsLoading"
                placeholder="Select class"
                @input="FillChSec()"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Section"
              invalid-feedback="Section is required."
              ref="ch_sec"
            >
              <v-select
                v-model="chObj.secID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="chSections"
                :reduce="(opt) => opt.id"
                label="section"
                :clearable="false"
                :disabled="clsLoading"
                placeholder="Select section"
                @input="checkChSec()"
              />
            </b-form-group>
          </b-col>

          <b-col class="mt-1" md="12">
            <b-button
              variant="success"
              class="mr-1"
              block
              :disabled="request"
              @click="saveCh()"
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else>Transfer</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-card>
      <b-row>
        <b-col
          v-for="(item, ind) in statusOptions"
          :key="ind"
          xl="2"
          lg="3"
          md="3"
          sm="12"
          cols="12"
        >
          <b-button
            block
            :variant="
              currentStatus == item.value ? 'primary' : 'outline-primary'
            "
            class="mb-1 mb-md-0"
            @click="LoadData(item.value)"
          >
            <span class="align-middle">{{ item.text }}</span>
          </b-button>
        </b-col>
      </b-row>
      <b-table
        class="mt-1"
        thead-tr-class="adjust-head"
        ref="selectableTable"
        :items="items"
        :fields="fields"
        :busy="dataLoading"
        show-empty
        responsives
        hover
        small
      >
        <template #empty="scope">
          <h3 class="mt-1 mb-1" style="text-align: center">No records found</h3>
        </template>

        <template #table-busy>
          <div
            class="d-flex justify-content-center mb-3"
            style="margin-top: 50px"
          >
            <b-spinner
              style="width: 3rem; height: 3rem"
              type="grow"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
        </template>

        <template #cell(index)="data">
          {{ data.index + 1 }}
          <!-- <b-badge variant="light-primary">
            {{ (currentPage - 1) * perPage + data.index + 1 }}
          </b-badge> -->
        </template>

        <template #cell(name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="44"
                :src="data.item.st.picture"
                variant="light-primary"
              />
            </template>

            <b-link
              class="font-weight-bold d-block text-nowrap"
              style="margin-bottom: 4px"
            >
              <b-badge variant="light-primary">
                {{ data.item.st.name }}
              </b-badge>
            </b-link>
            <small class="text-muted">
              <b-badge variant="light-primary" class="mr-50">
                GR -
                {{
                  reportDomain === "myskoolhpgs" ||
                  reportDomain === "myskoolprofectus"
                    ? data.item.st.grNoLocal
                    : data.item.st.grNo
                }}
              </b-badge>
              <b-badge variant="light-primary" class="mr-50">
                F# - {{ data.item.st.family_code }}
              </b-badge>
              <b-badge variant="light-primary" class="mr-50">
                RFID: {{ data.item.st.rfid_number }}
              </b-badge>
            </small>
            <b-link
              class="font-weight-bold d-block text-nowrap"
              style="margin-top: 4px"
            >
              <b-badge variant="light-primary">
                {{
                  reportDomain === "myskoolhpgs" ||
                  reportDomain === "myskoolprofectus"
                    ? "CID: "
                    : "ID: "
                }}

                {{
                  reportDomain === "myskoolhpgs" ||
                  reportDomain === "myskoolprofectus"
                    ? data.item.st.grNo
                    : data.item.st.id
                }}
              </b-badge>
            </b-link>
            <b-link
              v-if="['left', 'Left', 'alumni'].includes(data.item.st.status)"
              class="font-weight-bold d-block text-nowrap"
              style="margin-top: 4px"
            >
              <b-badge variant="light-primary">
                {{
                  new Date(data.item.st.date_of_leaving).toLocaleDateString(
                    "en-UK",
                    {
                      year: "numeric",
                      day: "numeric",
                      month: "short",
                    }
                  )
                }}
              </b-badge>
            </b-link>
            <b-link
              v-else-if="
                ['registered', 'Registered'].includes(data.item.st.status) &&
                data.item.st.date_of_registraion
              "
              class="font-weight-bold d-block text-nowrap"
              style="margin-top: 4px"
            >
              <b-badge variant="light-primary">
                {{
                  new Date(data.item.st.date_of_registraion).toLocaleDateString(
                    "en-UK",
                    {
                      year: "numeric",
                      day: "numeric",
                      month: "short",
                    }
                  )
                }}
              </b-badge>
            </b-link>
            <b-link
              v-else-if="
                ['present', 'Present'].includes(data.item.st.status) &&
                data.item.st.date_of_admission
              "
              class="font-weight-bold d-block text-nowrap"
              style="margin-top: 4px"
            >
              <b-badge variant="light-primary">
                {{
                  new Date(data.item.st.date_of_admission).toLocaleDateString(
                    "en-UK",
                    {
                      year: "numeric",
                      day: "numeric",
                      month: "short",
                    }
                  )
                }}
              </b-badge>
            </b-link>
          </b-media>
        </template>
        <template #cell(campus)="data">
          <b-badge variant="light-primary">
            {{ getCampus(data.item.st.campusID) }}
          </b-badge>
        </template>
        <template #cell(father_name)="data">
          <b-badge variant="light-primary">
            {{ data.item.st.father_name }}
          </b-badge>
        </template>
        <template #cell(surname)="data">
          <b-badge variant="light-primary">
            {{ data.item.st.surname }}
          </b-badge>
        </template>
        <template #cell(contact_father)="data">
          <b-badge variant="light-primary">
            {{ data.item.st.contact_father }}
          </b-badge>
        </template>
        <template #cell(class_current)="data">
          <b-badge variant="light-primary">
            {{ data.item.st.meta_class }}
          </b-badge>
        </template>
        <template #cell(monthly_fee)="data">
          <b-badge variant="light-primary">
            {{ data.item.st.monthly_fee }}
          </b-badge>
        </template>
        <template #cell(status)="data">
          <b-badge variant="light-primary">
            {{ data.item.us.status }}
          </b-badge>
        </template>

        <template #head(actions)="data">
          <div class="text-center">
            <span>{{ data.label }}</span>
          </div>
        </template>

        <template #cell(actions)="data">
          <div class="d-flex">
            <template v-if="data.item.us.status == 'pending'">
              <b-button
                variant="outline-primary"
                class="btn-icon mr-1"
                v-b-tooltip.hover.top
                title="Approve"
                @click="openApprove(data.item.us, data.item.st)"
              >
                <feather-icon icon="CheckCircleIcon" />
              </b-button>
              <b-button
                variant="outline-danger"
                class="btn-icon"
                v-b-tooltip.hover.top
                title="Reject"
                @click="openReject(data.item.us, data.item.st)"
              >
                <feather-icon icon="XIcon" />
              </b-button>
            </template>
            <b-button
              v-else
              variant="primary"
              class="btn-icon"
              v-b-tooltip.hover.top
              title="View"
              @click="openDetails(data.item.us, data.item.st)"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  // BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BContainer,
  BMediaAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BInputGroup,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { mapActions, mapGetters } from "vuex";
import RequestDetails from "./RequestDetails.vue";

export default {
  components: {
    RequestDetails,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    // BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,
    BFormCheckbox,
    BFormGroup,
    BForm,
    BContainer,
    BMediaAside,
    BSpinner,
    BCardTitle,
    BCardText,
    BSidebar,
    BInputGroupPrepend,
    BInputGroup,
    BFormTextarea,
    vSelect,
    flatPickr,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },
      rights: {},
      statusOptions: [
        { text: "Pending", value: "pending" },
        { text: "Accepted", value: "accepted" },
        { text: "Rejected", value: "rejected" },
      ],
      campuses: [],
      dataLoading: false,
      currentStatus: "pending",
      items: [],
      fields: [
        { label: "S.No", key: "index" },
        { label: "name", key: "name" },
        { label: "father name", key: "father_name" },
        { label: "contact", key: "contact_father" },
        // { label: "class", key: "class_current" },
        // { label: "Fees", key: "monthly_fee" },
        { label: "status", key: "status" },
        "actions",
      ],
      request: false,
      sidebarTitle: "",
      sidebarButton: "",
      visibility: false,
      approveObj: {},
      docLoading: false,
      docList: [],
      visibility2: false,
      rejectObj: {},
      reportDomain: this.$store.state.report.replace(/_/g, ""),
      visibility3: false,
      detailObj: null,
      chObj: {},
      visibility4: false,
      chOptions: [],
      chClasses: [],
      chSections: [],
      clsLoading: false,
      currentStd: {},
    };
  },
  created() {
    // let right = {};
    // this.$store.state.menu.forEach((el) => {
    //   el.children.forEach((ch) => {
    //     right[ch.route] = {
    //       view: ch.view,
    //       add: ch.add,
    //       edit: ch.edit,
    //       delete: ch.delete,
    //       hideFee: ch.hideFee,
    //       editCampus: ch.editCampus,
    //       disableImport: ch.disableImport,
    //       disableExport: ch.disableExport,
    //     };
    //   });
    // });
    // // console.log(right);
    // this.$store.commit("setRights", right);

    // if (!this.$store.state.rights[this.$route.name]) {
    //   // console.log(this.$store.state.rights[this.$route.name]);
    //   this.$router.replace({
    //     name: "misc-not-authorized",
    //   });
    // } else {
    //   this.rights = this.$store.state.rights[this.$route.name];
    // }

    if (this.reportDomain == "myskoolhpgs") {
      this.fields.splice(3, 0, { label: "campus", key: "campus" });
    }
    this.LoadCampuses();
    this.LoadData("pending");
  },
  methods: {
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    isImage(url) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/i.test(url);
    },
    openFile(url) {
      window.open(url, "_blank");
    },
    getCampus(id) {
      let obj = this.campuses.find((el) => el.id == id);
      return obj ? obj.branch : "";
    },

    openApprove(item, std) {
      this.approveObj = { ...item };
      this.currentStd = { ...std };
      if (item.approved_document)
        this.docList = item.approved_document.split(",");
      else this.docList = [];

      let text = "";
      if (["myskoolhpgs", "myskoolprofectus"].includes(this.reportDomain)) {
        text = `Gr: ${std.grNoLocal} CID: ${std.grNo}`;
      } else {
        text = `Gr: ${std.grNo} ID: ${std.id}`;
      }
      this.sidebarTitle = `${std.name}<br/>${text}`;
      this.sidebarButton =
        item.type == "transfer" ? "Approve & Transfer" : "Approve";
      this.visibility = true;
    },
    addDoc() {
      var axios = require("axios");
      if (this.$refs.reqDocs.files.length > 0) {
        let docs = this.$refs.reqDocs.files;
        this.docLoading = true;
        let formdata = new FormData();
        docs.forEach((el) => {
          formdata.append("file", el);
        });

        axios
          .post("https://upload.appick.io", formdata, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            let resp = e.data.myresp;
            resp.forEach((el) => {
              this.docList.push(el.path);
            });
            this.approveObj.approved_document = this.docList.join(",");
          })
          .catch((err) => {
            // console.log(err);
            this.docLoading = false;
          })
          .finally(() => (this.docLoading = false));
      }
    },
    removeDoc(index) {
      this.docList.splice(index, 1);
      this.approveObj.approved_document = this.docList.join(",");
    },
    CheckDate() {
      var elem = this.$refs["date"];
      if (!this.approveObj.leaving_date) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckNote() {
      var elem = this.$refs["note"];
      if (this.approveObj.note.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async approveReq() {
      if (this.CheckDate() == true && this.CheckNote() == true) {
        this.approveObj.status = "accepted";
        this.approveObj.approved_by = this.$store.state.userData.userID;
        //  console.log(this.approveObj)
        this.request = true;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "studentLeftRequest/ApproveRequest?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.approveObj.campusID,
          body: this.approveObj,
          message: `Student marked as left!`,
          context: this,
          token: this.$store.state.userData.token,
        });
        this.request = false;
        if (status) {
          this.LoadData(this.currentStatus);
          this.visibility = false;
          if (this.approveObj.type == "transfer") this.openCh(this.currentStd);
        }
      }
    },
    openReject(item, std) {
      this.rejectObj = { ...item };

      let text = "";
      if (["myskoolhpgs", "myskoolprofectus"].includes(this.reportDomain)) {
        text = `Gr: ${std.grNoLocal} CID: ${std.grNo}`;
      } else {
        text = `Gr: ${std.grNo} ID: ${std.id}`;
      }
      this.sidebarTitle = `${std.name}<br/>${text}`;
      this.visibility2 = true;
    },
    CheckReason() {
      var elem = this.$refs["rej_note"];
      if (this.rejectObj.note.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async rejectReq() {
      if (this.CheckReason() == true) {
        this.rejectObj.status = "rejected";
        this.rejectObj.leaving_date = new Date().toJSON();
        this.rejectObj.approved_by = this.$store.state.userData.userID;

        // console.log(this.rejectObj);

        this.request = true;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "studentLeftRequest/ApproveRequest?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.rejectObj.campusID,
          body: this.rejectObj,
          message: `Request rejected successfully!`,
          context: this,
          token: this.$store.state.userData.token,
        });
        this.request = false;
        if (status) {
          this.LoadData(this.currentStatus);
          this.visibility2 = false;
        }
      }
    },
    openDetails(item, std) {
      this.detailObj = { ...item };

      let text = "";
      if (["myskoolhpgs", "myskoolprofectus"].includes(this.reportDomain)) {
        text = `Gr: ${std.grNoLocal} CID: ${std.grNo}`;
      } else {
        text = `Gr: ${std.grNo} ID: ${std.id}`;
      }
      this.sidebarTitle = `${std.name}<br/>${text}`;
      this.visibility3 = true;
    },
    openCh(item) {
      this.chObj = {
        ...item,
        newCampus: 0,
        clsID: 0,
        secID: 0,
      };

      this.visibility4 = true;
      this.chSections = [];
      this.chClasses = [];
      this.chOptions = [];
    },
    async LoadClass() {
      this.checkChCamp();
      this.clsLoading = true;
      this.chObj.clsID = 0;
      this.chObj.secID = 0;
      this.chSections = [];

      var obj = {
        url:
          this.$store.state.domain +
          "myclasses/LoadClasses?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.chObj.newCampus +
          "&dID=0",
        token: this.$store.state.userData.token,
      };
      //  this.items =[];
      let myitem = await this.get(obj);
      this.chClasses = myitem;
      this.chOptions = this.chClasses.map((el) => el.cls);

      this.clsLoading = false;
    },
    FillChSec() {
      this.chObj.secID = 0;
      this.chSections = [];
      let obj = this.chClasses.find((el) => el.cls.id == this.chObj.clsID);
      if (obj && obj.sections.length > 0) {
        this.chSections = obj.sections;
      } else this.chSections = [];

      this.checkChClass();
    },
    checkChCamp() {
      var elem = this.$refs["ch_camp"];
      if (this.chObj.newCampus == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkChClass() {
      var elem = this.$refs["ch_cls"];
      if (this.chObj.clsID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkChSec() {
      var elem = this.$refs["ch_sec"];
      if (this.chObj.secID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async saveCh() {
      this.checkChCamp();
      this.checkChClass();
      this.checkChSec();
      if (
        this.checkChCamp() == false ||
        this.checkChClass() == false ||
        this.checkChSec() == false
      ) {
        this.$bvToast.toast("Please select all the required fields", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      }
      // else if (this.chObj.campusID == this.chObj.newCampus) {
      //   this.$bvToast.toast("Current campus and transfer campus are same", {
      //     title: "Error!",
      //     variant: "danger",
      //     toaster: "b-toaster-top-center",
      //   });
      // }
      else {
        let result = await Swal.fire({
          title: "Are you sure?",
          text: "Do you want to transfer the student?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Yes!",
        });

        if (result.isConfirmed) {
          this.request = true;
          let data = {
            sID: this.chObj.id,
            previousCampus: this.chObj.campusID,
            newCampus: this.chObj.newCampus,
            clsID: this.chObj.clsID,
            secID: this.chObj.secID,
            userID: this.$store.state.userData.userID,
          };
          // console.log(data);
          let status = await this.post({
            url:
              this.$store.state.domain +
              "Students/TransferCampus?db=" +
              this.$store.state.userData.db,
            body: data,
            message: "Student transferred successfully!",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          if (status) {
            this.visibility4 = false;
            this.LoadData(this.currentStatus);
          }
        }
      }
    },
    async LoadData(st) {
      this.currentStatus = st;
      this.dataLoading = true;
      let myitem = await this.get({
        url:
          this.$store.state.domain +
          "studentLeftRequest/LoadData?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&status=" +
          this.currentStatus,
        token: this.$store.state.userData.token,
      });
      // console.log(myitem);
      this.items = myitem;
      this.dataLoading = false;
    },
    async LoadCampuses() {
      var obj = {
        url:
          this.$store.state.domain +
          "campuses?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.campuses = await this.get(obj);
    },
  },
};
</script>
<style></style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
